/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "../../style/index.scss"
import { Col, Container, Row } from "react-bootstrap"

import root from "window-or-global";

if (root.document) {
  !function(e,t){if(!e.groove){var i=function(e,t){return Array.prototype.slice.call(e,t)},a={widget:null,loadedWidgets:{},classes:{Shim:null,Embeddable:function(){this._beforeLoadCallQueue=[],this.shim=null,this.finalized=!1;var e=function(e){var t=i(arguments,1);if(this.finalized){if(!this[e])throw new TypeError(e+"() is not a valid widget method");this[e].apply(this,t)}else this._beforeLoadCallQueue.push([e,t])};this.initializeShim=function(){a.classes.Shim&&(this.shim=new a.classes.Shim(this))},this.exec=e,this.init=function(){e.apply(this,["init"].concat(i(arguments,0))),this.initializeShim()},this.onShimScriptLoad=this.initializeShim.bind(this),this.onload=void 0}},scriptLoader:{callbacks:{},states:{},load:function(e,i){if("pending"!==this.states[e]){this.states[e]="pending";var a=t.createElement("script");a.id=e,a.type="text/javascript",a.async=!0,a.src=i;var s=this;a.addEventListener("load",(function(){s.states[e]="completed",(s.callbacks[e]||[]).forEach((function(e){e()}))}),!1);var n=t.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n)}},addListener:function(e,t){"completed"!==this.states[e]?(this.callbacks[e]||(this.callbacks[e]=[]),this.callbacks[e].push(t)):t()}},createEmbeddable:function(){var t=new a.classes.Embeddable;return e.Proxy?new Proxy(t,{get:function(e,t){return e instanceof a.classes.Embeddable?Object.prototype.hasOwnProperty.call(e,t)||"onload"===t?e[t]:function(){e.exec.apply(e,[t].concat(i(arguments,0)))}:e[t]}}):t},createWidget:function(){var e=a.createEmbeddable();return a.scriptLoader.load("groove-script","https://dd175ea6-486f-4a83-8bd6-98367edc06db.widget.cluster.groovehq.com/api/loader"),a.scriptLoader.addListener("groove-iframe-shim-loader",e.onShimScriptLoad),e}};e.groove=a}}(window,document);
  window.groove.widget = window.groove.createWidget();
  window.groove.widget.init('dd175ea6-486f-4a83-8bd6-98367edc06db', {});
}

const Layout = ({ title, children }) => {
  const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `);

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} title={title} /> */}
      {/* <script src="/eCrypt.min.js"/> */}
      <script src="https://secure.ewaypayments.com/scripts/eCrypt.min.js" />
      <script defer src="https://www.googletagmanager.com/gtag/js?id=UA-72525561-1"></script>
      <script defer type="text/javascript" dangerouslySetInnerHTML={{
        __html: `window.dataLayer = window.dataLayer || [];
 function gtag(){dataLayer.push(arguments);}
 gtag('js', new Date());
 gtag('config', 'UA-72525561-1');`}} />
      <script type="application/ld+json" dangerouslySetInnerHTML={{
        __html: `{
           "@context": "https://schema.org",
           "@type": "Corporation",
           "name": "VostroNet",
           "url": "https://vostronet.com",
           "logo": "https://vostronet.com/static/logo.svg",
           "contactPoint": [{
             "@type": "ContactPoint",
             "telephone": "+61-2-8319-2299",
             "contactType": "sales",
             "areaServed": ["AU","MY","SG","PH","US","GB"],
             "availableLanguage": "en"
           },{
             "@type": "ContactPoint",
             "telephone": "+64-9-887-1793",
             "contactType": "sales",
             "areaServed": "NZ",
             "availableLanguage": "en"
           },{
             "@type": "ContactPoint",
             "telephone": "1300-262-047",
             "contactType": "technical support",
             "contactOption": "TollFree",
             "areaServed": "AU",
             "availableLanguage": "en"
           },{
             "@type": "ContactPoint",
             "telephone": "0800-778-893",
             "contactType": "technical support",
             "contactOption": "TollFree",
             "areaServed": "NZ",
             "availableLanguage": "en"
           }],
           "sameAs": [
             "https://blog.vostronet.com",
             "https://www.facebook.com/vostronet.anz",
             "https://twitter.com/VostroNet",
             "https://www.instagram.com/vostronet/",
             "https://www.linkedin.com/company/vostronet/"
           ]
         }`
      }} />
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
